import React, { useState, useEffect, useMemo } from "react";
import { Formik, Form, Field, useFormik } from "formik";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import * as yup from "yup";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {useNavigate, useParams} from "react-router-dom"
import CircularProgress from '@mui/material/CircularProgress';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import MenuItem from "@mui/material/MenuItem";
import countryCodeList from 'country-codes-list'
import countryList from 'react-select-country-list'
import ImageModal from '../../components/ImageModal'
// import './registration.css';

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
const emailRegExp = /^((([!#$%&'*+\-/=?^_`{|}~\w])|([!#$%&'*+\-/=?^_`{|}~\w][!#$%&'*+\-/=?^_`{|}~\.\w]{0,}[!#$%&'*+\-/=?^_`{|}~\w]))[@]\w+([-.]\w+)*\.\w+([-.]\w+)*)$/;
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const indPhoneRegExpo = /^[1-9]\d{9}$/;
export const vendorSchema = yup.object().shape({
  org_name: yup.string().required("This is Required"),
  type_of_business: yup.string().required("This is Required"),
  owner_name: yup.string().required("This is Required"),
  owner_phone: yup
      .string().when('owner_phone_code', {
        is: '+91',
        then: (schema) => schema.matches(indPhoneRegExpo, { message: "Not a valid Phone Number" })
          .required("This is Required"),
        otherwise: (schema) => schema.matches(phoneRegExp, { message: "Not a valid Phone Number" })
          .required("This is Required"),
      }),
  /*
  owner_phone: yup
    .string()
    .matches(phoneRegExp, { message: "Not a valid Phone Number" })
    .min(10)
    .max(10)
    .required("This is Required"),
  */
  owner_phone_code: yup.string().required("This is Required"),
  // is_contact_person_same: yup.string().required("This is Required"),
  contact_person_name: yup.string().required("Must enter Contact Person Name"),
  // contact_person_phone: yup.string().required("Must enter Contact Person Mobile No."),
  contact_person_phone: yup.string().when("is_contact_person_same", {
    is: "No",
    then: (schema) => schema.when('contact_person_phone_code', {
      is: '+91',
      then: (schema) => schema.matches(indPhoneRegExpo, { message: "Not a valid Phone Number" })
        .required("Must enter Contact Person Mobile No."),
      otherwise: (schema) => schema.matches(phoneRegExp, { message: "Not a valid Phone Number" })
        .required("Must enter Contact Person Mobile No."),
    }),
  }),
  contact_person_phone_code: yup.string().required("Must enter code"),
  /*
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("This is Required"),
  */
  email: yup
    .string()
    .matches(emailRegExp,{message:"Please enter a valid email"})
    .required("This is Required"),
  address: yup.string().required("This is Required"),
  state: yup.string().required("This is Required"),
  city: yup.string().required("This is Required"),
  // pin_code: yup.string().min(6).max(6).required("This is Required"),
  pin_code: yup.string().when("country", {
    is: "India",
    then: (schema) => yup.string().min(6).max(6).required("This is Required"),
    otherwise: (schema) => yup.string().min(1).required("This is Required"),
  }),
  // is_gst_registered: yup.string().required("This is Required"),
  // gst_no: yup.string().when("is_gst_registered", {
  //   is: "Yes",
  //   then: yup.string().required("Must enter Gst No."),
  // }),
  // pan_no: yup.string().min(10).max(10).required("This is Required"),
  // adhaar_no: yup.string().min(12).max(12),
  // is_msme_registered: yup.string().required("This is Required"),
  // msme_no: yup.string().when("is_msme_registered", {
  //   is: "Yes",
  //   then: yup.string().required("Must enter MSME No."),
  // }),
  // rera_no: yup.string().required("This is Required"),
  rera_no: yup.string(),
  /*
  beneficiary_name: yup.string().required("This is Required"),
  account_no: yup.string().required("This is Required"),
  ifsc_code: yup.string().required("This is Required"),
  bank_name: yup.string().required("This is Required"),
  branch_name: yup.string().required("This is Required"),
  */
  beneficiary_name: yup.string(),
  account_no: yup.string(),
  ifsc_code: yup.string(),
  bank_name: yup.string(),
  branch_name: yup.string(),
  // gst_upload: yup.string().when("is_gst_registered", {
  //   is: "Yes",
  //   then: yup.string().required("You need to provide a file")
  //   }), 
  // pan_upload: yup.string().when("pan_no", {
  //   is: true,
  //   then: yup.string().required("You need to provide a file")
  //   }),
  // gst_upload: yup.string().required("You need to provide a file"),
  // pan_upload: yup.string().required("You need to provide a file"),  
  // adhaar_upload: yup
  //   .mixed()
  //   .test(
  //     "fileSize",
  //     "The file is too large",
  //     (value) => !value || (value && value.size <= 1024 * 1024 * 5)
  //   ),
  // msme_upload: yup.string().when("is_msme_registered", {
  //   is: "Yes",
  //   then: yup.string().required("You need to provide a file")
  //   }),
  // msme_upload: yup.string().required("You need to provide a file"), 
  // rera_upload: yup.string().required("You need to provide a file"),
  // cheque_upload: yup.string().required("You need to provide a file")
});

// yup
//       .mixed()
//       .required("You need to provide a file")
//       .test(
//         "fileSize",
//         "The file is too large",
//         (value) => !value || (value && value.size <= 1024 * 1024)
//       ),

const onSubmit = async (values, actions) => {
  // console.log(values);
  // console.log(actions);
  await new Promise((resolve) => setTimeout(resolve, 1000));
  // actions.resetForm();
};

const TextInput = ({ field, form, ...props }) => {
  // if (field.name === "org_name") {
  //   console.log(field, form);
  // }

  return (
    <TextField
      {...field}
      {...props}
      error={
        (form?.touched[field?.name] && form?.errors[field?.name]) ||
        (form?.errors[field?.name] && form?.submitCount > 0)
      }
      helperText={form?.touched[field?.name] && form?.errors[field?.name]}
    />
  );
};

const CustomButton = ({ field, form, ...props }) => {
  return (
    <button className="registrationbutton" {...field} {...props} >
      {props.children}
    </button>
  );
};


const reqFormat = (num) => {
  return (num).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
}

const getFileId = () => {
  var newDate = new Date();
  return parseInt(newDate.getFullYear()+''+reqFormat(newDate.getMonth()+1)+''+reqFormat(newDate.getDate())+''+reqFormat(newDate.getHours())+''+reqFormat(newDate.getMinutes())+''+reqFormat(newDate.getSeconds()))
}

function EditCpMaster() {
  let [isloading, setIsloading] = useState(false);
  const [initialValue, setInitialValue] = useState({})
  const navigate = useNavigate();
  let countries = useMemo(() => countryList().getData(), [])
  if(countries && countries.length > 0)  countries = countries.filter(data => (data.value === 'IN' || data.value === 'AE'));
  
  // const countryCodes = useMemo(() => countryCodeList.customList('countryCode', '{countryCallingCode}'), [])
  const countryCodes = {"AE":"971","IN":"91"};
  let {id} = useParams()

  let authToken;
  try{
    authToken = JSON.parse(localStorage.getItem('EMPLOYEE-AUTH'))
  }catch(err){
    authToken = null
  }

  useEffect(() => {
    fetchDataById()

    return () => {
      
    }
  }, [])
  // console.log(String(initialValue.org_name))

  const handleFileUpload = async(fileInput,fileUrl) => {
    // console.log(fileInput,fileUrl)
    var formdata = new FormData();
    formdata.append("file", fileInput,fileUrl+fileInput.name.replaceAll(" ","_") );
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    const response = await fetch(process.env.REACT_APP_BASE_URL+"/file/upload", requestOptions)
    const res = await response.json()
    if (response.status===501){
      NotificationManager.error(res.error.message, 'Form Not Submited', 8000);
    }
    return res.location
  }

  const multiFileUpload = async(org_name,uploadArray,values) => {
    let resArr = []
    const upload = await Promise.all(uploadArray.map(async(item) => {
      let nameString = `${getFileId()}${org_name.replaceAll(" ","_")+item.replaceAll(" ","_")}`
      if (values[item]){
        const res = await handleFileUpload(values[item],nameString)
        resArr.push([item,res])
        return [item,res]
      }
    }))
    return [upload,resArr]
  }

  const fetchDataById = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer" +" "+ authToken.token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+"/cp-master-details/"+id, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result)
        let tempResult = result
        tempResult.is_contact_person_same = "No"
        setInitialValue(result)
        // setRows(result.channel_partner)
      })
      .catch(error => console.log('error', error));
  }

  const handleSubmit = async (values, actions, errors) => {
    setIsloading(true)
    // console.log(values, actions, errors);

    let uploadArray = [
      "gst_upload",
      "pan_upload",
      "adhaar_upload",
      "msme_upload",
      "rera_upload",
      "cheque_upload",
    ]

    let uploadValues = {}
  
    const arr = await multiFileUpload(values.org_name,uploadArray,values)
    console.log(arr[1],"helllo")
    await arr[1].map(item => {
      uploadValues[item[0].split("_")[0]+"_uri"] = item[1]
    })
    let mod = arr[1]
    console.log(uploadValues,"moda")
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer "+authToken.token);

    var raw = JSON.stringify({
      org_name: values.org_name,
      type_of_business: values.type_of_business,
      owner_name: values.owner_name,
      owner_phone: values.owner_phone,
      owner_phone_code: values.owner_phone_code,
      contact_person_name: values.is_contact_person_same === "Yes" ? values.owner_name : values.contact_person_name,
      contact_person_phone: values.is_contact_person_same === "Yes" ? values.owner_phone : values.contact_person_phone,
      contact_person_phone_code: values.is_contact_person_same === "Yes" ? values.owner_phone_code : values.contact_person_phone_code,
      message: values.message,
      email: values.email,
      address: values.address,
      country: values.country,
      state: values.state,
      city: values.city,
      pin_code: values.pin_code,
      is_gst_registered: values.is_gst_registered,
      gst_no: values.gst_no,
      pan_no: values.pan_no,
      adhaar_no: values.adhaar_no,
      is_msme_registered: values.is_msme_registered,
      msme_no: values.msme_no,
      rera_no: values.rera_no || "",
      beneficiary_name: values.beneficiary_name || "",
      account_no: values.account_no || "",
      ifsc_code: values.ifsc_code || "",
      bank_name: values.bank_name || "",
      branch_name: values.branch_name || "",
      gst_uri: uploadValues.gst_uri ? uploadValues.gst_uri : values.gst_uri,
      pan_uri: uploadValues.pan_uri ? uploadValues.pan_uri : values.pan_uri,
      msme_uri: uploadValues.msme_uri ? uploadValues.msme_uri : values.msme_uri,
      adhaar_uri: uploadValues.adhaar_uri ? uploadValues.adhaar_uri : values.adhaar_uri,
      rera_uri: uploadValues.rera_uri ? uploadValues.rera_uri : values.rera_uri,
      cheque_uri: uploadValues.cheque_uri ? uploadValues.cheque_uri : values.cheque_uri,
    });

    var requestOptions = {
      method: 'PATCH',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+"/cp-master-details/"+id, requestOptions)
      .then(result => {
        // console.log(result.status)
        if (result.status%400<10){
          NotificationManager.error('Not authorised to edit the data', 'Error', 6000);
        }
        setIsloading(false)
        navigate("/admin/cp-master")
      })
      .catch(error => {
        console.log('error', error)
        NotificationManager.error('Something went wrong', 'Error', 6000);
        setIsloading(false)
      });
    
  };
  return (
    <div style={{ padding: "1rem", width:"95%", minWidth:"360px" }}>
      {/* <div style={{background:"#161617", marginBottom:"1rem", color:"white", borderRadius:"5px", alignContent:"center", alignItems:"center",textAlign:"center"}}>
        <img src={hoabl_logo} width="150px" />
        <h2 style={{color:"#FFFFFF"}}> Channel Partner Registration Form</h2>
      </div> */}
      {initialValue?.org_name && (
        <Formik
        initialValues={{...initialValue}}
        validationSchema={vendorSchema}
        onSubmit={handleSubmit}
      >
        {({ touched, errors, values, submitCount, setFieldValue, handleSubmit,submitForm }) => (
          <Form  >
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <div style={{ padding: "1rem", display:'flex', justifyContent:'space-between' }}>
                  <h4 style={{ font: "Jost" }}>Edit Channel Partner Details</h4>
                  <Button className="registrationbutton"  variant="contained" startIcon={<ArrowBackIosNewIcon />} onClick={() => navigate(-1) } >Back</Button>
                </div>
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  name="org_name"
                  label="Organisation Name"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              {/* <Grid item md={3} xs={6}>
                <div style={{ padding: "1rem" }}>
                  <p>Type of Business:</p>
                </div>
              </Grid> */}
              <Grid item md={6} xs={12}>
                <Field
                  label="Type of Business"
                  name="type_of_business"
                  required
                  fullWidth
                  select={true}
                  SelectProps={{
                    onClose: () => {
                      setTimeout(() => {
                        document.activeElement.blur();
                      }, 0);
                    },
                  }}
                  onChange={(e) => {
                    setFieldValue("type_of_business",e.target.value)
                  }}
                  component={TextInput}
                >
                  <MenuItem value="Domestic Company">Domestic Company</MenuItem>
                  <MenuItem value="Partnership">Partnership</MenuItem>
                  <MenuItem value="Proprietorship">Proprietorship</MenuItem>
                  <MenuItem value="Individual">Individual</MenuItem>
                  <MenuItem value="LLP">LLP</MenuItem>
                  <MenuItem value="HUF">HUF</MenuItem>
                  <MenuItem value="Trust">Trust</MenuItem>
                  <MenuItem value="AOP">AOP</MenuItem>
                  <MenuItem value="BOI">BOI</MenuItem>
                </Field>
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="owner_name"
                  label="Owner Name"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={1.5} xs={3}>
                <Field
                  name="owner_phone_code"
                  label="Code"
                  required
                  fullWidth
                  select={true}
                  SelectProps={{
                    onClose: () => {
                      setTimeout(() => {
                        document.activeElement.blur();
                      }, 0);
                    },
                  }}
                  onChange={(e) => {
                    setFieldValue("owner_phone_code",e.target.value)
                  }}
                  component={TextInput}
                >
                  {Object.keys(countryCodes).sort().map(item => <MenuItem key={item} value={"+"+countryCodes[item]}>{item}:+{countryCodes[item]}</MenuItem>)}
                </Field>
              </Grid>
              <Grid item md={4.5} xs={9}>
                <Field
                  type="text"
                  name="owner_phone"
                  label="Contact No."
                  required
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={8}>
                <div style={{ padding: "1rem" }}>
                  <p>Will the Owner handle the Channel Portal Administration?:</p>
                </div>
              </Grid>
              <Grid item md={6} xs={4}>
                <Field
                  name="is_contact_person_same"
                  label="Is Contact Person Same"
                  required
                  fullWidth
                  select={true}
                  SelectProps={{
                    onClose: () => {
                      setTimeout(() => {
                        document.activeElement.blur();
                      }, 0);
                    },
                  }}
                  onChange={(e) => {
                    setFieldValue("is_contact_person_same",e.target.value)
                  }}
                  component={TextInput}
                >
                  <MenuItem value="Yes">YES</MenuItem>
                  <MenuItem value="No">NO</MenuItem>
                </Field>
              </Grid>
              <Grid item md={6} xs={12}>
                {values?.is_contact_person_same === "No" && (
                  <>
                    <Field
                      type="text"
                      name="contact_person_name"
                      label="Contact Person Name"
                      fullWidth
                      component={TextInput}
                    />
                  </>
                )}
              </Grid>
              <Grid item md={1.5} xs={3}>
              {values?.is_contact_person_same === "No" && (
                  <>
                    <Field
                      name="contact_person_phone_code"
                      label="Code"
                      required
                      fullWidth
                      select={true}
                      SelectProps={{
                        onClose: () => {
                          setTimeout(() => {
                            document.activeElement.blur();
                          }, 0);
                        },
                      }}
                      onChange={(e) => {
                        setFieldValue("contact_person_phone_code",e.target.value)
                      }}
                      component={TextInput}
                    >
                      {Object.keys(countryCodes).sort().map(item => <MenuItem key={item} value={"+"+countryCodes[item]}>{item}:+{countryCodes[item]}</MenuItem>)}
                    </Field>
                  </>
                )}
              </Grid>
              <Grid item md={4.5} xs={9}>
                {values?.is_contact_person_same === "No" && (
                  <>
                    <Field
                      type="text"
                      name="contact_person_phone"
                      label="Contact Person Mobile No."
                      fullWidth
                      component={TextInput}
                    />
                  </>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="email"
                  name="email"
                  label="Email"
                  required
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="pin_code"
                  label="Pin Code"
                  required
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <p style={{ margin: "0 0 0 1rem" }}>Address*:</p>
                <Field
                  as="textarea"
                  name="address"
                  label="Address"
                  style={
                    (touched?.address && errors?.address) ||
                    (errors?.address && submitCount)
                      ? {
                          borderColor: "rgb(211,47,47)",
                          color: "rgb(211,47,47)",
                        }
                      : {}
                  }
                />
                {touched?.address && errors?.address && (
                  <p className="error">{errors?.address}</p>
                )}
              </Grid>
              <Grid item md={4} xs={12}>
                <Field
                  name="country"
                  label="Country"
                  required
                  fullWidth
                  select={true}
                  SelectProps={{
                    onClose: () => {
                      setTimeout(() => {
                        document.activeElement.blur();
                      }, 0);
                    },
                  }}
                  onChange={(e) => {
                    setFieldValue("country",e.target.value)
                  }}
                  component={TextInput}
                >
                  {countries.map(item => <MenuItem key={item.value} value={item.label}>{item.label}</MenuItem>)}
                </Field>
              </Grid>
              <Grid item md={4} xs={12}>
                <Field
                  type="text"
                  name="state"
                  label="State"
                  required
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Field
                  type="text"
                  name="city"
                  label="City"
                  required
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              { values?.country === 'India' && (
                <>
                  <Grid item md={12} xs={12}>
                    <div className="divider"></div>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    {/* <div style={{ padding: "1rem" }}> */}
                      <h4 style={{ font: "Jost" }}>Documentation</h4>
                    {/* </div> */}
                  </Grid>
                  {/* <Grid item md={3} xs={6}>
                    <div style={{ padding: "1rem" }}>
                      <p>Is Gst Registered:</p>
                    </div>
                  </Grid> */}
                  <Grid item md={6} xs={12}>
                    <Field
                      name="is_gst_registered"
                      label="Is GST Registered"
                      required
                      fullWidth
                      select={true}
                      SelectProps={{
                        onClose: () => {
                          setTimeout(() => {
                            document.activeElement.blur();
                          }, 0);
                        },
                      }}
                      onChange={(e) => {
                        setFieldValue("is_gst_registered",e.target.value)
                      }}
                      component={TextInput}
                    >
                      <MenuItem value="Yes">YES</MenuItem>
                      <MenuItem value="No">NO</MenuItem>
                    </Field>               
                  </Grid>
                  <Grid item md={4} xs={8}>
                    {values?.is_gst_registered === "Yes" && (
                      <>
                        <Field
                          type="text"
                          name="gst_no"
                          label="GST No."
                          fullWidth
                          component={TextInput}
                        />
                        {errors?.gst_upload && (
                          <p className="error">{errors?.gst_upload}</p>
                        )}
                      </>
                    )}
                  </Grid>
                  <Grid item md={1} xs={2}>
                    <div style={{ padding: "1rem 0 0 1rem" }}>
                      {values.gst_uri && <ImageModal url={values.gst_uri} ls_token_key={"EMPLOYEE-AUTH"}/>}
                    </div>
                  </Grid>
                  <Grid item md={1} xs={2}>
                    {values?.is_gst_registered === "Yes" && (
                      <div style={{ padding: "1rem 0 1rem 0" }}>
                        <label for="gst_upload">
                          <CloudUploadIcon /><span style={{color:"rgb(211,47,47)"}}>*</span>
                        </label>
                        <input
                          type="file"
                          accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                          id="gst_upload"
                          name="gst_upload"
                          onChange={(event) => {
                            setFieldValue(
                              "gst_upload",
                              event.currentTarget.files[0]
                            );
                          }}
                          hidden
                        />
                      </div>
                    )}
                  </Grid>
                  <Grid item md={4} xs={8}>
                    <Field
                      type="text"
                      name="pan_no"
                      label="PAN No."
                      fullWidth
                      component={TextInput}
                    />
                    {touched?.pan_no && errors?.pan_upload && (
                      <p className="error">{errors?.pan_upload}</p>
                    )}
                  </Grid>
                  <Grid item md={1} xs={2}>
                    <div style={{ padding: "1rem 0 0 1rem" }}>
                      {values.pan_uri && <ImageModal url={values.pan_uri} ls_token_key={"EMPLOYEE-AUTH"}/>}
                    </div>
                  </Grid>
                  <Grid item md={1} xs={2}>
                    <div style={{ padding: "1rem 0 1rem 0" }}>
                      <label for="pan_upload">
                        <CloudUploadIcon /><span style={{color:"rgb(211,47,47)"}}>*</span>
                      </label>
                      <input
                        type="file"
                        accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                        id="pan_upload"
                        name="pan_upload"
                        onChange={(event) => {
                          setFieldValue("pan_upload", event.currentTarget.files[0]);
                        }}
                        hidden
                      />
                    </div>
                  </Grid>
                  <Grid item md={4} xs={8}>
                    <Field
                      type="text"
                      name="adhaar_no"
                      label="Adhaar No."
                      fullWidth
                      component={TextInput}
                    />
                    {touched?.adhaar_no && errors?.adhaar_upload && (
                      <p className="error">{errors?.adhaar_upload}</p>
                    )}
                  </Grid>
                  <Grid item md={1} xs={2}>
                    <div style={{ padding: "1rem 0 0 1rem" }}>
                      {values?.adhaar_uri && <ImageModal url={values?.adhaar_uri} ls_token_key={"EMPLOYEE-AUTH"}/>}
                    </div>
                  </Grid>
                  <Grid item md={1} xs={2}>
                    <div style={{ padding: "1rem 0 1rem 0" }}>
                      <label for="adhaar_upload">
                        <CloudUploadIcon /><span style={{color:"rgb(211,47,47)"}}></span>
                      </label>
                      <input
                        type="file"
                        accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                        id="adhaar_upload"
                        name="adhaar_upload"
                        onChange={(event) => {
                          setFieldValue(
                            "adhaar_upload",
                            event.currentTarget.files[0]
                          );
                        }}
                        hidden
                      />
                    </div>
                  </Grid>
                  {/* <Grid item md={3} xs={6}>
                    <div style={{ padding: "1rem" }}>
                      <p>Is MSME Registered:</p>
                    </div>
                  </Grid> */}
                  <Grid item md={6} xs={12}>
                    <Field
                      label="Is MSME Registered"
                      name="is_msme_registered"
                      required
                      fullWidth
                      select={true}
                      SelectProps={{
                        onClose: () => {
                          setTimeout(() => {
                            document.activeElement.blur();
                          }, 0);
                        },
                      }}
                      onChange={(e) => {
                        setFieldValue("is_msme_registered",e.target.value)
                      }}
                      component={TextInput}
                    >
                      <MenuItem value="Yes">YES</MenuItem>
                      <MenuItem value="No">NO</MenuItem>
                    </Field>
                    {/* {touched?.is_msme_registered && errors?.is_msme_registered && (
                      <p className="error">{errors?.is_msme_registered}</p>
                    )} */}
                  </Grid>
                  <Grid item md={4} xs={8}>
                    {values?.is_msme_registered === "Yes" && (
                      <>
                        <Field
                          type="text"
                          name="msme_no"
                          label="MSME No."
                          fullWidth
                          component={TextInput}
                        />
                        {errors?.msme_upload && (
                          <p className="error">{errors?.msme_upload}</p>
                        )}
                      </>
                    )}
                  </Grid>
                  <Grid item md={1} xs={2}>
                    <div style={{ padding: "1rem 0 0 1rem" }}>
                      {values?.msme_uri && <ImageModal url={values?.msme_uri} ls_token_key={"EMPLOYEE-AUTH"}/>}
                    </div>
                  </Grid>
                  <Grid item md={1} xs={2}>
                    {values?.is_msme_registered === "Yes" && (
                      <div style={{ padding: "1rem 0 1rem 0" }}>
                        <label for="msme_upload">
                          <CloudUploadIcon /><span style={{color:"rgb(211,47,47)"}}>*</span>
                        </label>
                        <input
                          type="file"
                          accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                          id="msme_upload"
                          name="msme_upload"
                          onChange={(event) => {
                            setFieldValue(
                              "msme_upload",
                              event.currentTarget.files[0]
                            );
                          }}
                          hidden
                        />
                      </div>
                    )}
                  </Grid>
                  <Grid item md={4} xs={8}>
                    <Field
                      type="text"
                      name="rera_no"
                      label="RERA No."
                      fullWidth
                      component={TextInput}
                    />
                    {touched?.rera_no && errors?.rera_upload && (
                      <p className="error">{errors?.rera_upload}</p>
                    )}
                  </Grid>
                  <Grid item md={1} xs={2}>
                    <div style={{ padding: "1rem 0 0 1rem" }}>
                      {values?.rera_uri && <ImageModal url={values?.rera_uri} ls_token_key={"EMPLOYEE-AUTH"}/>}
                    </div>
                  </Grid>
                  <Grid item md={1} xs={2}>
                    <div style={{ padding: "1rem 0 1rem 0" }}>
                      <label for="rera_upload">
                        <CloudUploadIcon /><span style={{color:"rgb(211,47,47)"}}>{/* * */}</span>
                      </label>
                      <input
                        type="file"
                        accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                        id="rera_upload"
                        name="rera_upload"
                        onChange={(event) => {
                          setFieldValue(
                            "rera_upload",
                            event.currentTarget.files[0]
                          );
                        }}
                        hidden
                      />
                    </div>
                  </Grid>
              </>
            )}

              <Grid item md={12} xs={12}>
                <div className="divider"></div>
              </Grid>
              <Grid item md={12} xs={12}>
                <h4 style={{ font: "Jost" }}>Bank Details</h4>
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="beneficiary_name"
                  label="Beneficiary Name"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="account_no"
                  label="Account No."
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="ifsc_code"
                  label="IFSC Code"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="bank_name"
                  label="Bank Name"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="branch_name"
                  label="Branch Name"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={4} xs={8}>
                <div style={{ padding: "1rem" }}>
                  <p>Upload Cancelled Cheque/ Passbook 1st Page:</p>
                  {errors?.cheque_upload && (
                    <p className="error">{errors?.cheque_upload}</p>
                  )}
                </div>
              </Grid>
              <Grid item md={1} xs={2}>
                <div style={{ padding: "1rem 0 0 1rem" }}>
                  {values?.cheque_uri && <ImageModal url={values?.cheque_uri} ls_token_key={"EMPLOYEE-AUTH"}/>}
                </div>
              </Grid>
              <Grid item md={1} xs={2}>
                <div style={{ padding: "1rem 0 1rem 0" }}>
                  <label for="cheque_upload">
                    <CloudUploadIcon /><span style={{color:"rgb(211,47,47)"}}>{/* * */}</span>
                  </label>
                  <input
                    type="file"
                    accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                    id="cheque_upload"
                    name="cheque_upload"
                    onChange={(event) => {
                      setFieldValue(
                        "cheque_upload",
                        event.currentTarget.files[0]
                      );
                    }}
                    hidden
                  />
                </div>
              </Grid>
              <Grid item md={12} xs={12}>
                <Field id="submitEdit" name="submitEdit" type="submit"  component={CustomButton}>
                    {isloading ? <CircularProgress sx={{color:"#FFFFFF"}} size="1.5rem"/>:"Submit"}
                </Field>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      )}
    </div>
  )
}

export default EditCpMaster