import React,{ useEffect } from 'react'
import hoabl_logo from "../assets/hoabl_logo_white.png"
import { useLocation, useNavigate } from 'react-router-dom'


function Error() {
  const location = useLocation();
  const { record, message } = location.state || {};

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
  }

  return (
    <div style={{ padding: "1rem", width:"80%",minWidth:"360px"}}>
      <div style={{background:"#161617", width:"100%", padding:"1rem", marginBottom:"1rem", color:"white", borderRadius:"5px", alignContent:"center", alignItems:"center",textAlign:"center"}}>
        <img src={hoabl_logo} width="150px" />
        <h2 style={{color:"#FFFFFF", fontFamily:"Jost"}}>Error!!!</h2>
        <p style={{color:"#FFFFFF", fontFamily:"Jost"}}>Something Went Wrong, Please contact administrator </p>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        {
          record &&
          Object.keys(record).length > 0 &&
          <div>
            <h2>{message}</h2>
            <table style={{ textAlign: "left" }}>
              <tr><td><h5>HOABL ID: </h5></td><td></td><td>{record.hoabl_id}</td></tr>
              <tr><td><h5>ORGANISATION NAME: </h5></td><td></td><td>{record.org_name}</td></tr>
              <tr><td><h5>OWNER NAME: </h5></td><td></td><td>{record.owner_name}</td></tr>
              <tr><td><h5>LOGIN WITH: </h5></td><td></td><td>{record.contact_person_phone_code} - {record.contact_person_phone}</td></tr>
              <tr><td></td><td><button className="registrationbutton" onClick={handleClick}>Login</button></td><td></td></tr>
            </table>
          </div>
        }
      </div>
    </div>
  )
}

export default Error